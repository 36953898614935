import _core from "./core";
import _timestamp from "../type/timestamp";
import _merge from "../type/merge";
import _binary from "../type/binary";
import _omap from "../type/omap";
import _pairs from "../type/pairs";
import _set from "../type/set";
var exports = {};
exports = _core.extend({
  implicit: [_timestamp, _merge],
  explicit: [_binary, _omap, _pairs, _set]
});
export default exports;