var exports = {};

function isNothing(subject) {
  return typeof subject === "undefined" || subject === null;
}

function isObject(subject) {
  return typeof subject === "object" && subject !== null;
}

function toArray(sequence) {
  if (Array.isArray(sequence)) return sequence;else if (isNothing(sequence)) return [];
  return [sequence];
}

function extend(target, source) {
  var index, length, key, sourceKeys;

  if (source) {
    sourceKeys = Object.keys(source);

    for (index = 0, length = sourceKeys.length; index < length; index += 1) {
      key = sourceKeys[index];
      target[key] = source[key];
    }
  }

  return target;
}

function repeat(string, count) {
  var result = "",
      cycle;

  for (cycle = 0; cycle < count; cycle += 1) {
    result += string;
  }

  return result;
}

function isNegativeZero(number) {
  return number === 0 && Number.NEGATIVE_INFINITY === 1 / number;
}

exports.isNothing = isNothing;
exports.isObject = isObject;
exports.toArray = toArray;
exports.repeat = repeat;
exports.isNegativeZero = isNegativeZero;
exports.extend = extend;
export default exports;