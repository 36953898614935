import _type from "../type";
var exports = {};
var Type = _type;

function resolveYamlMerge(data) {
  return data === "<<" || data === null;
}

exports = new Type("tag:yaml.org,2002:merge", {
  kind: "scalar",
  resolve: resolveYamlMerge
});
export default exports;