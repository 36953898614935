var exports = {};

function formatError(exception, compact) {
  var where = "",
      message = exception.reason || "(unknown reason)";
  if (!exception.mark) return message;

  if (exception.mark.name) {
    where += "in \"" + exception.mark.name + "\" ";
  }

  where += "(" + (exception.mark.line + 1) + ":" + (exception.mark.column + 1) + ")";

  if (!compact && exception.mark.snippet) {
    where += "\n\n" + exception.mark.snippet;
  }

  return message + " " + where;
}

function YAMLException(reason, mark) {
  // Super constructor
  Error.call(this);
  this.name = "YAMLException";
  this.reason = reason;
  this.mark = mark;
  this.message = formatError(this, false); // Include stack trace in error object

  if (Error.captureStackTrace) {
    // Chrome and NodeJS
    Error.captureStackTrace(this, this.constructor);
  } else {
    // FF, IE 10+ and Safari 6+. Fallback for others
    this.stack = new Error().stack || "";
  }
} // Inherit from Error


YAMLException.prototype = Object.create(Error.prototype);
YAMLException.prototype.constructor = YAMLException;

YAMLException.prototype.toString = function toString(compact) {
  return this.name + ": " + formatError(this, compact);
};

exports = YAMLException;
export default exports;