import _loader from "./lib/loader";
import _dumper from "./lib/dumper";
import _type from "./lib/type";
import _schema from "./lib/schema";
import _failsafe from "./lib/schema/failsafe";
import _json from "./lib/schema/json";
import _core from "./lib/schema/core";
import _default from "./lib/schema/default";
import _exception from "./lib/exception";
import _binary from "./lib/type/binary";
import _float from "./lib/type/float";
import _map from "./lib/type/map";
import _null from "./lib/type/null";
import _pairs from "./lib/type/pairs";
import _set from "./lib/type/set";
import _timestamp from "./lib/type/timestamp";
import _bool from "./lib/type/bool";
import _int from "./lib/type/int";
import _merge from "./lib/type/merge";
import _omap from "./lib/type/omap";
import _seq from "./lib/type/seq";
import _str from "./lib/type/str";
var exports = {};
var loader = _loader;
var dumper = _dumper;

function renamed(from, to) {
  return function () {
    throw new Error("Function yaml." + from + " is removed in js-yaml 4. " + "Use yaml." + to + " instead, which is now safe by default.");
  };
}

exports.Type = _type;
exports.Schema = _schema;
exports.FAILSAFE_SCHEMA = _failsafe;
exports.JSON_SCHEMA = _json;
exports.CORE_SCHEMA = _core;
exports.DEFAULT_SCHEMA = _default;
exports.load = loader.load;
exports.loadAll = loader.loadAll;
exports.dump = dumper.dump;
exports.YAMLException = _exception; // Re-export all types in case user wants to create custom schema

exports.types = {
  binary: _binary,
  float: _float,
  map: _map,
  null: _null,
  pairs: _pairs,
  set: _set,
  timestamp: _timestamp,
  bool: _bool,
  int: _int,
  merge: _merge,
  omap: _omap,
  seq: _seq,
  str: _str
}; // Removed functions from JS-YAML 3.0.x

exports.safeLoad = renamed("safeLoad", "load");
exports.safeLoadAll = renamed("safeLoadAll", "loadAll");
exports.safeDump = renamed("safeDump", "dump");
export default exports;
export const Type = exports.Type,
      Schema = exports.Schema,
      FAILSAFE_SCHEMA = exports.FAILSAFE_SCHEMA,
      JSON_SCHEMA = exports.JSON_SCHEMA,
      CORE_SCHEMA = exports.CORE_SCHEMA,
      DEFAULT_SCHEMA = exports.DEFAULT_SCHEMA,
      load = exports.load,
      loadAll = exports.loadAll,
      dump = exports.dump,
      YAMLException = exports.YAMLException,
      types = exports.types,
      safeLoad = exports.safeLoad,
      safeLoadAll = exports.safeLoadAll,
      safeDump = exports.safeDump;